import { notify } from "@/components/Shares/Notification";
import EStateAsyncStatus from "@/constants/stateAsyncState";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { signUp } from "@/service/customer";
import { cart } from "@/store/slices/cartSlice";
import { put as storePut } from "@/utils/storageHelper";
import { login } from "@/store/slices/loginSlice";
import { Button } from "@nextui-org/react";
import { isEmpty, isEqual } from "lodash";
import { useState } from "react";
import { TOKEN } from "@/constants/storeLocation";

const SignUpForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [passwordShort, setPasswordShort] = useState(false);
  const [noCaptal, setNoCaptal] = useState(false);
  const [noSymbol, setNoSymbol] = useState(false);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const cartState = useAppSelector(cart);
  const dispatch = useAppDispatch();

  const validatePasswordCaptal = (password: string) => {
    const regex = /^[^A-Z]*$/;
    return regex.test(password);
  };
  const validatePasswordSymbol = (password: string) => {
    const regex = /^[a-zA-Z0-9]*$/;
    return regex.test(password);
  };

  const handleSignUp = async () => {
    if (isEmpty(email)) {
      setEmailEmpty(true);
      return;
    } else {
      setEmailEmpty(false);
    }

    if (password.length <= 6) {
      setPasswordShort(true);
      return;
    } else {
      setPasswordShort(false);
    }

    if (validatePasswordCaptal(password)) {
      setNoCaptal(true);
      return;
    } else {
      setNoCaptal(false);
    }

    if (validatePasswordSymbol(password)) {
      setNoSymbol(true);
      return;
    } else {
      setNoSymbol(false);
    }

    if (!isEqual(password, confirmPassword)) {
      setPasswordNotMatch(true);
      return;
    } else {
      setPasswordNotMatch(false);
    }

    if (
      isEqual(cartState.status, EStateAsyncStatus.succeeded) &&
      cartState.cart
    ) {
      const payload = {
        email,
        password,
        shoppingCartId: cartState.cart.id,
      };
      try {
        const response = await signUp(payload);
        if (!response.token) {
          if (Array.isArray(response.data.message)) {
            notify(response.data.message[0], "error");
          } else {
            notify(response.data.message, "error");
          }
        } else {
          storePut(TOKEN, response.token);
          dispatch(login());
        }
      } catch (e) {}
    }
  };
  return (
    <div className="w-full max-w-[370px] flex flex-col items-center">
      <h2 className="text-lg font-semibold mb-4">SIGN UP</h2>
      <div className="flex flex-col w-full mb-1">
        <label className="text-sm">Email Address:</label>
        <input
          className={`border rounded-md p-2 focus:outline-icon ${
            emailEmpty ? "border-red-500" : "border-stroke"
          }`}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <small
          className={`text-red-500 ${emailEmpty ? "visible" : "invisible"}`}
        >
          Your Email Address is empty.
        </small>
      </div>
      <div className="flex flex-col w-full mb-1">
        <label className="text-sm">Password:</label>
        <input
          type="password"
          className={`border border-stroke rounded-md p-2 focus:outline-icon
          ${
            passwordShort || noSymbol || noCaptal
              ? "border-red-500"
              : "border-stroke"
          }`}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <small
          className={`text-red-500 h-6 ${
            passwordShort || noCaptal || noSymbol ? "visible" : "invisible"
          }`}
        >
          {passwordShort
            ? "Your Password must be more than 6 characters."
            : noCaptal
              ? "Your Password must contain at least 1 capital letter."
              : noSymbol &&
                "Your Password must contain at least 1 special character."}
        </small>
      </div>
      <div className="flex flex-col w-full">
        <label className="text-sm">Confirm Password:</label>
        <input
          type="password"
          className={`border border-stroke rounded-md p-2 focus:outline-icon
          ${passwordNotMatch ? "border-red-500" : "border-stroke"}`}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <small
          className={`text-red-500 ${
            passwordNotMatch ? "visible" : "invisible"
          }`}
        >
          Your Passwords do not match, please try again.
        </small>
      </div>
      <div className="flex flex-col w-full mt-6 gap-4">
        <Button
          radius="none"
          className="bg-gray-950 text-white py-6 hover:bg-icon"
          onClick={handleSignUp}
        >
          SIGN UP
        </Button>
      </div>
    </div>
  );
};

export default SignUpForm;
