import { loginWithGoogle } from "@/utils/firebase";
import { FcGoogle } from "react-icons/fc";
import { thirdPartyLogin } from "@/service/customer";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { cart } from "@/store/slices/cartSlice";
import { isEqual } from "lodash";
import EStateAsyncStatus from "@/constants/stateAsyncState";
import { notify } from "@/components/Shares/Notification";
import { put as storePut } from "@/utils/storageHelper";
import { TOKEN } from "@/constants/storeLocation";
import { login } from "@/store/slices/loginSlice";

const GoogleLogin = () => {
  const cartState = useAppSelector(cart);
  const dispatch = useAppDispatch();

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault;
    const res1 = await loginWithGoogle();
    if (res1.success) {
      if (
        isEqual(cartState.status, EStateAsyncStatus.succeeded) &&
        cartState.cart
      ) {
        const payload = {
          email: `${res1.user?.email}`,
          firstName: `${res1.user?.displayName}`,
          shoppingCartId: cartState.cart.id,
        };
        try {
          const response = await thirdPartyLogin(payload);
          if (!response.token) {
            if (Array.isArray(response.data.message)) {
              notify(response.data.message[0], "error");
            } else {
              notify(response.data.message, "error");
            }
          } else {
            storePut(TOKEN, response.token);
            dispatch(login());
            notify(`Welcome to Priceworth Furniture!`, "success");
          }
        } catch (e) {
          //console.log(e);
        }
      }
    }
  };

  return (
    <button onClick={handleClick}>
      <div className="border w-12 h-12 flex items-center justify-center hover:shadow-card hover:border-icon transition-all duration-500">
        <FcGoogle className="text-4xl" />
      </div>
    </button>
  );
};

export default GoogleLogin;
