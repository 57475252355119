import { useEffect, useState, MouseEvent } from "react";
import { notify } from "@/components/Shares/Notification";
import { useRouter } from "next/router";
import {
  login,
  sendForgotPassword,
  sendVerificationCode,
  verifyCode,
} from "@/service/customer";
import { Button } from "@nextui-org/react";
import { isEmpty, isEqual } from "lodash";
import { put as storePut } from "@/utils/storageHelper";
import { TOKEN } from "@/constants/storeLocation";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { customer, fetchCustomer } from "@/store/slices/customerSlice";
import EStateAsyncStatus from "@/constants/stateAsyncState";
import { login as setLogin } from "@/store/slices/loginSlice";
import GoogleLogin from "../GoogleLogin";
import MicrosoftLogin from "../MicrosoftLogin";

const LoginForm = () => {
  const [passwordLogin, setPasswordLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [passwordShort, setPasswordShort] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [passwordLoading, setPasswordLoading] = useState(false);

  useEffect(() => {
    let timer: any;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      setLoading(false);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const router = useRouter();
  const dispatch = useAppDispatch();
  const customerState = useAppSelector(customer);

  const handleSuccess = (token: string) => {
    storePut(TOKEN, token);
    dispatch(fetchCustomer());
    if (isEqual(customerState.status, EStateAsyncStatus.succeeded)) {
      dispatch(setLogin());
      notify(
        `Welcome Back! ${customerState.customerInfo?.firstName}`,
        "success",
      );
      router.push("/");
    }
  };

  const handleSendCode = async () => {
    const res = await sendVerificationCode(email);
    if (res.statusCode === 200) {
      notify("Verification code sent to your mailbox", "success");
      setLoading(true);
      setCountdown(60);
    } else {
      notify(res.data.message, "error");
    }
  };

  const handlePasswordLogin = async () => {
    setPasswordLoading(true);
    if (isEmpty(email)) {
      setEmailEmpty(true);
      return;
    }
    if (password.length <= 6) {
      setPasswordShort(true);
      return;
    }

    const payload = {
      email,
      password,
    };
    try {
      const response = await login(payload);
      if (!response.token) {
        notify(response.data.message, "error");
        setPasswordLoading(false);
      } else {
        handleSuccess(response.token);
        setPasswordLoading(true);
      }
    } catch (e) {}
  };

  const handleVerificationCodeLogin = async () => {
    const payload = {
      email,
      code,
    };
    const res = await verifyCode(payload);
    if (res.statusCode === 200) {
      handleSuccess(res.data.token);
    } else {
      notify(res.data.message, "error");
    }
  };

  const handleForgotPassword = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    sendForgotPassword(email);
    notify(
      "We sent a password reset link to your mailbox if this email is register with us!",
      "success",
    );
  };

  if (passwordLogin) {
    return (
      <div className="w-full max-w-[370px] flex flex-col items-center">
        <h2 className="text-lg font-semibold mb-4">LOG IN</h2>
        <div className="flex flex-col w-full mb-1">
          <label className="text-sm">Email Address:</label>
          <input
            className={`border rounded-md p-2 focus:outline-icon select-none ${
              emailEmpty ? "border-red-500" : "border-stroke"
            }`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <small
            className={`text-red-500 ${emailEmpty ? "visible" : "invisible"}`}
          >
            Your Email Address is empty.
          </small>
        </div>
        <div className="flex flex-col w-full">
          <label className="text-sm">Password:</label>
          <input
            type="password"
            className={`border select-none border-stroke rounded-md p-2 focus:outline-icon
          ${passwordShort ? "border-red-500" : "border-stroke"}`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <small
            className={`text-red-500 ${
              passwordShort ? "visible" : "invisible"
            }`}
          >
            Your Password must be more than 6 characters.
          </small>
        </div>
        <small
          className="self-end underline text-icon cursor-pointer"
          onClick={handleForgotPassword}
        >
          Forgot Your Password?
        </small>
        <div className="flex flex-col w-full mt-6 gap-4">
          <Button
            radius="none"
            className="bg-gray-950 text-white py-6 hover:bg-icon"
            isLoading={passwordLoading}
            onClick={handlePasswordLogin}
          >
            LOG IN
          </Button>
          <Button
            radius="none"
            className="bg-white text-gray-950 border border-gray-950 py-6 hover:bg-black hover:text-white"
            onClick={() => setPasswordLogin(false)}
          >
            LOG IN WITH VERIFICATION CODE
          </Button>
        </div>
        <div className="flex w-full items-center gap-4 mt-4">
          <p>Login with:</p>
          <GoogleLogin />
          <MicrosoftLogin />
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full max-w-[370px] flex flex-col items-center">
        <h2 className="text-lg font-semibold mb-4">LOG IN</h2>
        <div className="flex flex-col w-full mb-1">
          <label className="text-sm">Email Address:</label>
          <input
            className={`border rounded-md p-2 focus:outline-icon ${
              emailEmpty ? "border-red-500" : "border-stroke"
            }`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <small
            className={`text-red-500 ${emailEmpty ? "visible" : "invisible"}`}
          >
            Your Email Address is empty.
          </small>
        </div>

        <div className="flex flex-col w-full">
          <label className="text-sm">Verfication code:</label>
          <div className="flex w-full items-center ">
            <input
              className="border border-stroke rounded-md p-2 focus:outline-icon w-full"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <Button
              radius="none"
              className="bg-gray-950 w-16 text-white py-4 hover:bg-icon"
              isLoading={loading}
              onClick={handleSendCode}
            >
              {loading ? `${countdown}` : "Send Code"}
            </Button>
          </div>
        </div>
        <div className="flex flex-col w-full mt-6 gap-4">
          <Button
            radius="none"
            className="bg-gray-950 text-white py-6 hover:bg-icon"
            onClick={handleVerificationCodeLogin}
          >
            LOG IN WITH VERIFICATION CODE
          </Button>
          <Button
            radius="none"
            className="bg-white text-gray-950 border border-gray-950 py-6 hover:bg-black hover:text-white"
            onClick={() => setPasswordLogin(true)}
          >
            LOG IN WITH PASSWORD
          </Button>
        </div>
        <div className="flex w-full items-center gap-4 mt-4">
          <p>Login with:</p>
          <GoogleLogin />
          <MicrosoftLogin />
        </div>
      </div>
    );
  }
};

export default LoginForm;
