import { initializeApp } from "firebase/app";
import {
  GithubAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { isNull } from "lodash";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export const loginWithGithub = () => {
  const provider = new GithubAuthProvider();

  signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GithubAuthProvider.credentialFromResult(result);
      const token = !isNull(credential) ? credential.accessToken : "";
      const user = result.user;
      return {
        success: true,
        user,
        token,
      };
    })
    .catch(() => {
      return { success: false };
    });
};

export const loginWithGoogle = async () => {
  const provider = new GoogleAuthProvider();

  try {
    const result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = !isNull(credential) ? credential.accessToken : "";
    const user = result.user;
    return {
      success: true,
      user,
      token,
    };
  } catch (error) {
    console.error("Login failed", error);
    return {
      success: false,
    };
  }
};

export const loginWithMicrosoft = async () => {
  const provider = new OAuthProvider("microsoft.com");

  try {
    const result = await signInWithPopup(auth, provider);
    const credential = OAuthProvider.credentialFromResult(result);
    const token = !isNull(credential) ? credential.accessToken : "";
    const user = result.user;
    return {
      success: true,
      user,
      token,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};
